import React from 'react';
import './CamNang.css';

import DauLung from '../images/daulung.jpg';
import GianCotSong from '../images/giancotsong.jpg';
import ThoatViDiaDem from '../images/thoatvidiadem.jpg';
import ThanKinhToa from '../images/thankinhtoa.jpg';

  

const CamNang = ({ selectedMachine }) => {

const renderContent = () => {
    switch (selectedMachine ) {
        case 'Đau lưng':
            return (
                <div>
                  <h3>Nguyên nhân mỏi lưng và cách trị</h3>
                    <table>
                      <tr>
                          <td>
                            <img src={DauLung}/>
                          </td>
                      <td>
                        <p>Nguyên nhân mỏi lưng có thể bao gồm vận động ít hoặc không đúng cách, tư thế không đúng, sai lệch cột sống, các vấn đề sinh học như thoát vị đĩa đệm, viêm khớp, và tình trạng cơ bắp yếu.</p>
                        <p>Căng thẳng tâm lý và lối sống không lành mạnh cũng có thể gây ra mỏi lưng.</p>
                        <p>Để giảm nguy cơ mỏi lưng, hãy duy trì một lối sống lành mạnh, thực hiện thường xuyên các bài tập thể dục, và sử dụng kỹ thuật đúng khi nâng vật nặng.</p>
                      </td>
                    </tr>
                  </table>
                </div>
            );


        case 'Kéo Giãn cột sống':
            return (
                <div className="divBlog">
                    <h3>Kéo giãn cột sống là gì? Tác dụng và ưu điểm</h3>
                    <table>
                        <tr>
                            <td>
                        <img src={GianCotSong}/>
                            </td>
                        <td>
                            <p>Kéo giãn cột sống là phương pháp vật lý trị liệu giãn ra cơ bắp và các đốt sống trong cột sống. </p>
                            <p>Phương pháp này giúp giảm đau, tăng cường linh hoạt, và giảm căng thẳng cơ bắp. </p>
                            <p>Ưu điểm của kéo giãn cột sống là không cần phẫu thuật, không dùng thuốc, hiệu quả ngay sau khi thực hiện, và có thể thực hiện tại nhà hoặc dưới sự giám sát của chuyên gia vật lý trị liệu.</p>
                        </td>
                      </tr>
                    </table>
                </div>
            );


        case 'Thoát vị đĩa đệm':
            return (
                <div className="divBlog">
                    <h3>Thoát vị đĩa đệm là gì?</h3>
                    <table>
                    <tr>
                        <td>
                    <img src={ThoatViDiaDem} />
                    </td>
                    <td>
                      <p>Thoát vị đĩa đệm là tình trạng khi đĩa đệm trong cột sống bị thoát khỏi vị trí bình thường, gây ra đau lưng, đau lan xuống chân, và các triệu chứng khác như kiến thức yếu, cảm giác rụng rời hoặc tê.</p>
                      <p>Phương pháp kéo cột sống là một giải pháp hiệu quả trong điều trị thoát vị đĩa đệm, giúp cải thiện sự linh hoạt và giảm đau cho bệnh nhân. </p>
                      
                      <p>Điều trị có thể bao gồm sử dụng thuốc, vật lý trị liệu, hoặc trong một số trường hợp, phẫu thuật, tùy thuộc vào mức độ và vị trí của thoát vị đĩa đệm cũng như tình trạng sức khỏe tổng thể của bệnh nhân.</p>
                  </td>
              </tr>
        </table>
    </div>
    );


        case 'Thần kinh tọa':
            return (
                <div className="divBlog">
                    <h3>Bệnh thần kinh tọa là gì?</h3>
                    <table>
                          <tr>
                              <td>
                          <img src={ThanKinhToa} />
                          </td>
                          <td>
                              <p>Bệnh thần kinh tọa, hay còn gọi là thoái hóa cột sống thắt lưng, là một tình trạng phổ biến do sự lão hóa tự nhiên của cột sống ở vùng thắt lưng.</p>
                              <p> Nó thường gây ra đau lưng, đau lan xuống chân, và cảm giác rụng rời hoặc tê. </p>
                              <p>Nguyên nhân bao gồm tuổi tác, chấn thương, áp lực dài hạn, và yếu tố di truyền.</p>
                              <p>Triệu chứng thường bao gồm đau lưng, đau lan xuống chân, và giảm sức mạnh.</p>
                              <p>Điều trị thường bao gồm vật lý trị liệu, thuốc giảm đau, và trong một số trường hợp, phẫu thuật.</p>
                              
                              </td>
                            </tr>
                          </table>
                        </div>
                       );  
                default:
            return null;
    }
};


return (
    <div className="thiet-bi">
        {/* Bỏ phần button ở đây, vì đã chuyển xử lý sự kiện lên TabsSection.js */}
        {selectedMachine && (
            <div className="section">
                {renderContent()}
            </div>
        )}
    </div>
);
};

export default CamNang;



