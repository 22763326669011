import React from 'react';
import './GioiThieu.css';
import MayDienXung from '../images/maydienxung.jpg';
import MayKeoCotSong from '../images/keocotsong.jpg';
import MayNenEp from '../images/maynenep.jpg';
import MaySieuAm from '../images/maysieuam.jpg';
import CuaHang from '../images/cuahang.jpg';
import KeoCotSong from '../images/keocotsong.jpg';
import LienHe from '../images/lienhe.jpg';
import MatTienPK from '../images/mat_tien_pk.jpg'
import MatTienPK1 from '../images/mat_tien_pk1.jpg'
import SliderImages from './SliderImages'; 


function GioiThieu() {
    const images = [CuaHang, MaySieuAm, MayNenEp, KeoCotSong, LienHe, MatTienPK, MatTienPK1, MayKeoCotSong, MayDienXung];
    return (
        <div id="GioiThieuRes">
            <h2 id="ha">Giới thiệu về phòng tập</h2>
            <table id="tb1">
                <th>
                    <td id="GioiThieuRes">
                        <SliderImages images={images} caseType={1}/>
                    </td>
                    <td id="GioiThieuRes">
                        <p>
                            👉 Phòng tập là không gian được trang bị các thiết bị hiện đại và tiện nghi, hoàn hảo để phục vụ cho việc tập luyện và điều trị các vấn đề sức khỏe. Đây là nơi mà bạn có thể tìm thấy sự hòa quyện giữa khoa học và thể chất, nơi bạn có thể tập luyện một cách chuyên nghiệp và hiệu quả nhất.

                        </p>
                        <p>
                            👉 Trong môi trường phòng tập, bạn sẽ có cơ hội trải nghiệm và sử dụng các thiết bị và máy móc chuyên dụng, được thiết kế đặc biệt để giúp cải thiện sức khỏe và thể chất của bạn. Từ máy chạy bộ và xe đạp tới các thiết bị tập luyện chức năng đa dạng khác, mọi người đều có thể tìm thấy phương tiện phù hợp để phát triển và rèn luyện cơ thể của mình.
                        
                        </p>
                        <p>
                            👉 Bên cạnh việc tập luyện, phòng tập còn là nơi thú vị để khám phá các phương pháp và kỹ thuật tập luyện mới, cũng như chia sẻ kiến thức và kinh nghiệm với cộng đồng. Đây không chỉ là nơi để rèn luyện cơ thể mà còn là nơi để rèn luyện tinh thần và tạo ra một cộng đồng đam mê và hỗ trợ lẫn nhau trong việc đạt được mục tiêu sức khỏe và thể chất.

                        </p>
                        <p>
                            👉 Với sự đa dạng và tiện ích của mình, phòng tập không chỉ là một nơi để tập luyện, mà còn là một trung tâm của sức khỏe và sự phát triển cá nhân.

                        </p>

                        <h1>Image Slider</h1>
                        <SliderImages images={images} caseType={2}/>
          
                    </td>
                </th>
            </table>
        </div>
    );
}

export default GioiThieu;
