import React from 'react';
import './GioiThieu.css';
import MayDienXung from '../images/maydienxung.jpg';
import MayKeoCotSong from '../images/keocotsong.jpg';
import MayNenEp from '../images/maynenep.jpg';
import MaySieuAm from '../images/maysieuam.jpg';
import GioiThieu from './GioiThieu';


const TabGioiThieu = ({ selectedMachine }) => {
    const renderContent = () => {
        switch (selectedMachine) {
            case 'Giới thiệu về phòng tập':
                return (
                    <GioiThieu/>
                );
            case 'Máy điều trị điện xung':
                return (
                    <div className="section">
                        <table id="tb1">
                            <th>
                                <td>
                                    <img src={MayDienXung} alt="Máy điện xung" />
                                </td>
                                <td>
                                    <h2 id="ha">MÁY ĐIỀU TRỊ ĐIỆN XUNG</h2>

                                    <p>👉 Thiết bị kinh tế với khả năng sử dụng cho hai bệnh nhân cùng lúc thông qua 2 kênh riêng biệt.</p>
                                    <p>👉 Có tùy chọn hút và các chương trình điều trị khác nhau để đảm bảo hiệu quả tối đa.</p>
                                    <p>👉 Có thể điều chỉnh cường độ chân không và tần số mạch vào các điện cực để mang lại cảm giác mát mẻ và dễ chịu cho bệnh nhân.</p>
                                    <p>👉 Trang bị thiết bị an toàn và hệ thống để máy luôn bắt đầu ở vị trí số 0 hiện tại.</p>
                                    <p>👉 Cung cấp 2 cực, 4 cực điện khí hóa riêng lẻ cho việc điều trị đa dạng.</p>
                                    <p>👉 Có 6 chương trình tự động và 3 chương trình cố định, giúp điều trị hiệu quả và dễ dàng.</p>
                                
                                </td>
                            </th>
                       
                        </table>
                        
                    </div>
                );
            case 'Máy kéo cột sống':
                return (
                    <div className="section">
                        <h2 id="ha">MÁY KÉO CỘT SỐNG</h2>
                        <table id='tb1'>
                            <th>
                                <td>
                                    <img src={MayKeoCotSong} alt="Máy kéo cột sống" />

                                </td>
                                <td>
                                <p>Máy kéo cột sống là một công cụ hiệu quả được sử dụng trong điều trị các vấn đề liên quan đến cột sống, như đau lưng, thoái hóa đốt sống, v.v. </p>
                                <ul>
                                    <p><strong>👉 Kéo giãn cột sống cổ:</strong> Phương pháp này giúp giãn cột sống cổ để giảm đau và cải thiện sự linh hoạt của cổ.</p>
                                    <p><strong>👉 Kéo giãn cột sống thắt lưng:</strong> Đây là phương pháp giãn cách các đốt sống thắt lưng để giảm áp lực và đau ở khu vực này.</p>
                                    <p><strong>👉 Các tư thế kéo giãn:</strong> Bạn có thể kéo giãn cột sống ở nhiều tư thế khác nhau, như nằm ngửa hoặc ngồi, để tạo điều kiện tốt nhất cho quá trình điều trị.</p>
                                </ul>
                                </td>
                            </th>
                        </table>
                        
                    </div>
                );
            case 'Máy nén ép trị liệu Gapo':
                return (
                    <div className="section">
                        <h2 id="ha">MÁY TRỊ LIỆU GAPO</h2>
                        <table id='tb1'>
                            <th>
                                <td>
                                    <img src={MayNenEp} alt="Máy nén ép trị liệu Gapo" />

                                </td>
                                <td>
                                    <p><strong>Công nghệ trong máy nén ép trị liệu Gapo:</strong></p>
                                    <p>👉 Máy nén ép trị liệu sử dụng công nghệ nén khí tính bằng mmHg để nén vào các khoang từ dưới lên, từ vùng xa tim đến vùng gần tim.</p>
                                </td>
                            </th>
                        </table>
                        
                    </div>
                );
            case 'Máy siêu âm đa tầng':
                return (
                    <div className="section">
                        <h2 id="ha">MÁY SIÊU ÂM ĐA TẦNG</h2>
                        <table id='tb1'>
                            <th>
                                <td>
                                    <img src={MaySieuAm} alt="Máy siêu âm đa tầng" />

                                </td>
                                <td>
                                    <p><strong>👉 Tần số:</strong> Sử dụng tần số đa tần 1 và 3 MHz.</p>

                                </td>
                            </th>
                        </table>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="thiet-bi">
            {selectedMachine && (
                <div className="section">
                    {renderContent()}
                </div>
            )}
        </div>
    );
};

export default TabGioiThieu;
