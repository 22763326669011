// App.js
import React from 'react';
import TabsSection from './components/TabsSection';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <TabsSection />
      <Footer />
    </div>
  );
}

export default App;
