// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
        <table id='footer'>
          <th>
            <td>
              <h1>Địa chỉ</h1>
              <p>9/5 Quang Trung</p>
              <p>Phường Xuân Khánh, Quận Ninh Kiều, TP. Cần Thơ</p>
            </td>

            <td>
              <h1>Giờ hoạt động</h1>
              <p>Thứ 2 - Thứ 7: Từ 8h đến 19h</p>
              <p>Chủ nhật: Từ 8h đến 12h</p>
            </td>
            
            <td>
              <h1>Liên hệ</h1>
              <p>0859 430 093</p>
            </td>
          </th>
        </table>
  );
};

export default Footer;