// Body.js
import React from 'react';
import bg from '../images/BANGHIEU01.jpg';
import './BatDau.css';
import GioiThieu from './GioiThieu';


const BatDau = () => {
  
  return (
    <div id="batdauRes">
      <img id="imageHeader" src={bg} alt="Background" />
      <line></line>
      <GioiThieu/>
    </div>
  );
};

export default BatDau;
