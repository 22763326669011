import React from 'react';
import './LienHe.css';

function LienHe() {
    return (
      <body>
        <iframe width="100%" height="450" frameborder="0" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=1000&amp;height=400&amp;hl=en&amp;q=%209/5%20Quang%20Trung%20%20Ph%C6%B0%E1%BB%9Dng%20Xu%C3%A2n%20Kh%C3%A1nh,%20Qu%E1%BA%ADn%20Ninh%20Ki%E1%BB%81u%20C%E1%BA%A7n%20Th%C6%A1+(Trung%20Nhan)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"/>
        <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=eb9c1b5cd1038d30349206023549c1e650e615dc'/>
      </body>
    );
  }
export default LienHe;
