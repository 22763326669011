import React, { useState, useEffect } from 'react';
import './SliderImages.css';

const Slider = ({ images, currentIndex, nextSlide, prevSlide }) => {
  return (
    <div className="slider-container">
      <button className="prev" onClick={prevSlide}>
        &#10094;
      </button>
      <div className="slider">
        <div className="prev-image">
          <img src={images[currentIndex === 0 ? images.length - 1 : currentIndex - 1]} alt={`prev-slide-${currentIndex}`} />
        </div>
        <div className="current-image">
          <img src={images[currentIndex]} alt={`slide-${currentIndex}`} />
        </div>
        <div className="next-image">
          <img src={images[currentIndex === images.length - 1 ? 0 : currentIndex + 1]} alt={`next-slide-${currentIndex}`} />
        </div>
      </div>
      <button className="next" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
};

const BigImage = ({ images, currentIndex }) => {
  return (
    <div className="big-image">
      <img src={images[currentIndex]} alt={`big-slide-${currentIndex}`} />
    </div>
  );
};

const SliderImages = ({ images, caseType }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 1500); // Khoảng thời gian chuyển đổi (1.5s)

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div>
      {caseType === 1 ? ( // Nếu caseType là 1, trả về BigImage
        <BigImage images={images} currentIndex={currentIndex} />
      ) : ( // Nếu caseType là 2, trả về Slider
        <Slider images={images} currentIndex={currentIndex} nextSlide={nextSlide} prevSlide={prevSlide} />
      )}
    </div>
  );
};

export default SliderImages;
